import OrderCard from './OrderCard.vue';
import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import OrderListUtil,{IOrderListDataObj} from './orderListUtil';
import language from './orderLanguage'
import PiCard from '@/views/project/order/order/pi/PiCard.vue';
import OrderDetail from '@/views/project/order/order/order/orderDetail/OrderDetail.vue';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
export default defineComponent ({
    name: 'OrderList',
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IOrderListDataObj=reactive<IOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'意向订单管理',
                paramHeight:54,//tab的高度
                queryParam: {},
                modelComp: OrderCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/order/pageData')
            },
            otherParams:{
                activeName:'all',
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new OrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //新增意向订单
        const addOrderHandler=async ()=>{

        }
        //拟定PI
        const orderPI=async(row:any)=>{
            await utils.UtilPub.openDialog({orderId:row['F_ID'],proxy:proxy,addOrEdit:'add',modelComp:PiCard,ownerComp:dataObj.pageListRef,
                cardFrom:'OrderList',overflow:'hidden',title: '拟定PI',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //订单详情
        const orderDetail=async(row:any)=>{
            await utils.UtilPub.openDialog({id:row['F_ID'],proxy:proxy,addOrEdit:'load',modelComp:OrderDetail,ownerComp:dataObj.pageListRef,
                cardFrom:'OrderList',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }

        //tab切换事件
        const handleChange=(tabName:string)=>{
            dataObj.pageList.queryParam.queryType=tabName;
            dataObj.pageListRef.queryHandler(true);
        }
        const exportExcel=(fields:any, labels:any,data:any,engineInst:any)=>{
            proxy.excelUtils(labels, data, engineInst.listParam.gridTitle + utils.UtilPub.getCurrentDate());
        }
        return{
            ...toRefs(dataObj),formatPageInfo,addOrderHandler,orderPI,orderDetail,handleChange,exportExcel
        }
    }
});